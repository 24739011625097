import React from 'react';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import Media from 'src/utils/media';

import gearLoadingSvg from 'src/assets/img/gear-loading.svg';

const Container = styled(Row)`
  border-radius: 8px;
  background: linear-gradient(64.25deg, #5db8fb 15.64%, #35ea94 94.68%);
`;

const Icon = styled.img`
  width: auto;
  max-height: 42px;
`;

const StatusTitle = styled.span`
  font-size: 24px;
  font-weight: 300;
  color: #ffffff;

  ${Media.sm`
    font-size: 21px;
  `}
  ${Media.md`
    font-size: 21px;
  `}
`;

const Countdown = styled.span`
  font-size: 36px;
  font-weight: 300;
  color: #ffffff;

  ${Media.md`
    font-size: 22px;
  `}

  > label {
    font-size: 14px;
    font-weight: 300;
    color: #ffffff;
  }
`;

const WarmUpNotifier = ({ duration }) => {
  const { t } = useTranslation();

  const mm = duration && ('0' + duration.minutes()).slice(-2);
  const ss = duration && ('0' + duration.seconds()).slice(-2);
  const ms = duration && ('' + duration.milliseconds()).slice(0, 1);

  return (
    <Container className="justify-content-between align-content-center mx-0 mb-2 mb-lg-3 animate__animated">
      <Col className="p-4 px-lg-4 py-lg-2">
        <Row>
          <Col xs={2} md={1}>
            <Icon src={gearLoadingSvg.src} className="mt-lg-1" />
          </Col>
          <Col xs={10} md={11} lg={7}>
            <StatusTitle className="mt-lg-2 d-block">{t('components.containers.promoNotifier.warmingUp')}</StatusTitle>
          </Col>
          <Col xs={12} md={12} lg={4} className="text-center text-md-end">
            <Countdown>
              <label className="mx-2">{t('components.containers.promoNotifier.trafficStarts')}</label>
              {`${mm || '00'}:${ss || '00'}.${ms || '0'}`}
            </Countdown>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default WarmUpNotifier;
