import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';
import Image from 'next/image';
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu as RTDropdownMenu,
  DropdownItem as RTDropdownItem,
} from 'reactstrap';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import io from 'socket.io-client';
import Media from 'src/utils/media';

import {
  ADMIN_DASHBOARD,
  API_ME_FEATURE,
  LOGOUT,
  PANEL_BILLING,
  PANEL_DASHBOARD,
  PANEL_HELP,
  PANEL_PROFILE,
  PANEL_PROJECTS,
} from 'src/const/routes';
import logoSvg from 'src/assets/img/logo.svg';
import {
  incrementVisitors,
  incrementVisits,
  loadAppState,
  pushVisit,
  incrementProjectVisitors,
  incrementProjectVisits,
  tickMinute,
  updateProjectStatus,
} from 'src/actions/app';
import { apiUrl } from 'src/config.client';
import moment from 'moment';
import ActiveUser from 'src/containers/active-user';
import PackageName from 'src/containers/package-name';
import WarmUpNotifier from 'src/containers/notifier/warm-up-notifier';
import Animation from 'src/components/common/animation';
import authRequest from 'lib/authRequest';
import { WARM_UP_ENABLED } from 'src/const/features';
import { getCurrentTimezone } from 'src/utils/date';
import {
  CONNECT,
  ON_PROJECT_DEACTIVATE,
  ON_PROJECT_PAUSE,
  ON_PROJECT_RESTART,
  ON_PROJECT_START,
  ON_VISIT,
} from 'src/const/socketEvents';
import { ACTIVE, INACTIVE, PAUSED, RESTARTING } from 'src/const/projectStatuses';
import DemoOfferNotifier from 'src/containers/notifier/demo-offer-notifier';
import ProjectExpiredNotifier from 'src/containers/notifier/project-expired-notifier';
import GaCheckNotifier from '../../containers/notifier/ga-check-notifier';
import AffiliateBalance from '../../containers/affiliate-balance';
import BlackFridayNotifier from '../../containers/notifier/black-friday-notifier';
import { BLACK_FRIDAY_NOTIFIER, PACKAGE_EXPIRED, WARM_UP } from '../../const/notifiers';
import { removeNotifier } from '../../actions/user';
import Cookies from 'js-cookie';
import { COOKIE_SWITCH_USER } from '../../const/cookies';

const Wrapper = styled(Container)`
  background: linear-gradient(#1e1e2f, #1e1e24);
  min-height: 100vh;
`;

const Header = styled.header``;

const HeaderLink = styled(NavLink)`
  color: #ffffff;
  font-weight: 300;

  &:hover {
    color: #c5c5c5;
  }

  &:focus {
    color: #ef68a3;
  }

  &.active {
    color: #ef68a3;

    &:hover {
      color: #ffffff;
    }

    &:focus {
      color: #ef68a3;
    }
  }

  > i {
    margin-right: 10px;
  }
`;

const Logo = styled(Image)``;

const Navigation = styled(Nav)`
  ${Media.xl`
    margin-left: -15px;
  `}
`;

const DropdownMenu = styled(RTDropdownMenu)`
  background: #27293b;
  border: none !important;
  box-shadow: none !important;
`;

const DropdownItem = styled(RTDropdownItem)`
  color: #b9b9b9;
  font-size: 12px;

  &:hover {
    background: none !important;
    color: #ffffff;
  }

  > i {
    margin-right: 10px;
  }
`;

const Content = styled(Container)`
  min-height: 84vh;
`;

const Copyright = styled.small`
  text-align: center;
  color: #8a8a8a;
  font-size: 12px;
`;

let socket = null;

const useSocket = () => {
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    if (token && !socket) {
      const switchUser = Cookies.get(COOKIE_SWITCH_USER);
      socket = io(apiUrl, {
        transports: ['websocket', 'polling'],
        query: { token: token, timezone: getCurrentTimezone(), _switch_user: switchUser ?? '' },
      });
    }

    return function cleanup() {
      if (socket) {
        socket.disconnect();
        socket = null;
      }
    };
  }, [token]);

  return socket;
};

let warmUpInterval = undefined;
let demoOfferInterval = undefined;
let blackFridayInterval = undefined;

const Notifier = ({ token }) => {
  const dispatch = useDispatch();
  const demoOffer = useSelector((state) => state.user.notifiers.demoOffer);
  const warmUp = useSelector((state) => state.user.notifiers.warmUp);
  const gaCheck = useSelector((state) => state.user.notifiers.gaCheck);
  const packageExpired = useSelector((state) => state.user.notifiers.packageExpired);
  const blackFriday = useSelector((state) => state.user.notifiers[BLACK_FRIDAY_NOTIFIER]);
  const [warmUpDuration, setWarmUpDuration] = useState(null);
  const [demoOfferDuration, setDemoOfferDuration] = useState(null);
  const [blackFridayDuration, setBlackFridayDuration] = useState(null);
  const [gaCheckEnabled, setGaCheckEnabled] = useState(false);
  const visits = useSelector((state) => state.app.counters.visits);

  // init warm up notifier duration
  useEffect(async () => {
    if (warmUp && visits < 1) {
      let duration = warmUp.duration;

      if (!warmUpInterval) {
        warmUpInterval = setInterval(() => {
          duration = moment.duration(duration.asMilliseconds() - 100, 'milliseconds');

          if (duration.asMilliseconds() > 0) {
            setWarmUpDuration(duration);
          } else {
            setWarmUpDuration(null);
            clearInterval(warmUpInterval);
            warmUpInterval = undefined;
          }
        }, 100);
      }
    } else if (warmUp && visits > 0) {
      dispatch(removeNotifier(WARM_UP));
      authRequest(API_ME_FEATURE.replace('{feature}', WARM_UP_ENABLED), token, { method: 'DELETE' });
    }

    return function cleanup() {
      setWarmUpDuration(null);
      clearInterval(warmUpInterval);
      warmUpInterval = undefined;
    };
  }, [warmUp, visits]);

  const turnOffDemoOffer = () => {
    if (demoOfferInterval) {
      setDemoOfferDuration(null);
      clearInterval(demoOfferInterval);
      demoOfferInterval = undefined;
    }
  };

  // init demo offer notifier duration
  useEffect(async () => {
    if (demoOffer) {
      let duration = demoOffer.duration;

      if (!demoOfferInterval) {
        demoOfferInterval = setInterval(() => {
          duration = moment.duration(duration.asMilliseconds() - 1000, 'milliseconds');

          if (duration.asMilliseconds() > 0) {
            setDemoOfferDuration(duration);
          } else {
            turnOffDemoOffer();
          }
        }, 1000);
      }
    }

    return function cleanup() {
      turnOffDemoOffer();
    };
  }, [demoOffer]);

  const turnOffBlackFriday = () => {
    if (blackFridayInterval) {
      setBlackFridayDuration(null);
      clearInterval(blackFridayInterval);
      blackFridayInterval = undefined;
    }
  };

  // init black friday offer notifier duration
  useEffect(async () => {
    if (blackFriday) {
      let duration = blackFriday.duration;

      if (!blackFridayInterval) {
        blackFridayInterval = setInterval(() => {
          duration = moment.duration(duration.asMilliseconds() - 1000, 'milliseconds');

          if (duration.asMilliseconds() > 0) {
            setBlackFridayDuration(duration);
          } else {
            turnOffBlackFriday();
          }
        }, 1000);
      }
    }

    return function cleanup() {
      turnOffBlackFriday();
    };
  }, [blackFriday]);

  //init ga check notifier
  useEffect(() => {
    if (gaCheck) {
      setGaCheckEnabled(gaCheck.enabled);
    }
  }, [gaCheck]);

  return (
    <>
      {!!blackFridayDuration && (
        <>
          <Animation show={!!blackFridayDuration} inAnimation={'fadeInDown'} outAnimation={'fadeOutUp'}>
            <BlackFridayNotifier duration={blackFridayDuration} turnOff={turnOffBlackFriday} />
          </Animation>
        </>
      )}
      {!blackFridayDuration && (
        <>
          <Animation show={!!warmUp && !!warmUpDuration} inAnimation={'fadeInDown'} outAnimation={'fadeOutUp'}>
            <WarmUpNotifier duration={warmUpDuration} />
          </Animation>
          <Animation show={!!packageExpired} inAnimation={'fadeInDown'} outAnimation={'fadeOutUp'}>
            <ProjectExpiredNotifier />
          </Animation>
          <Animation
            show={gaCheckEnabled && !packageExpired && !warmUpDuration}
            inAnimation={'fadeInDown'}
            outAnimation={'fadeOutUp'}
          >
            <GaCheckNotifier turnOff={() => setGaCheckEnabled(false)} />
          </Animation>
          <Animation
            show={!!demoOfferDuration && !warmUpDuration && !packageExpired && !gaCheckEnabled}
            inAnimation={'fadeInDown'}
            outAnimation={'fadeOutUp'}
          >
            <DemoOfferNotifier duration={demoOfferDuration} turnOff={turnOffDemoOffer} />
          </Animation>
        </>
      )}
    </>
  );
};

const PanelLayout = ({ children }) => {
  const socket = useSocket();
  const { t } = useTranslation();
  const router = useRouter();
  let tickTimeout = null;
  let tickInterval = null;
  const user = useSelector((state) => state.user);
  const switchUser = Cookies.get(COOKIE_SWITCH_USER);

  const dispatch = useDispatch();

  // on create
  useEffect(() => {
    dispatch(loadAppState());

    const diff = moment.duration(moment().add(1, 'minute').startOf('minute').diff(moment()));

    // setup app state ticker on every minute start
    tickTimeout = setTimeout(() => {
      dispatch(tickMinute());

      tickInterval = setInterval(() => {
        dispatch(tickMinute());
      }, 60000);
      clearTimeout(tickTimeout);
    }, diff.asMilliseconds());

    return function cleanup() {
      clearInterval(tickInterval);
      clearTimeout(tickTimeout);
    };
  }, []);

  // on socket create
  useEffect(() => {
    if (socket) {
      socket.on(CONNECT, () => {
        console.log(`Socket connection successfully established`);
      });

      socket.on(ON_VISIT, (visit) => {
        dispatch(pushVisit(visit));
        dispatch(incrementVisits());
        dispatch(incrementProjectVisits(visit.projectId));

        if (visit.isFirstVisit) {
          dispatch(incrementVisitors());
          dispatch(incrementProjectVisitors(visit.projectId));
        }
      });

      socket.on(ON_PROJECT_START, ({ projectId }) => {
        dispatch(updateProjectStatus(projectId, ACTIVE));
      });

      socket.on(ON_PROJECT_RESTART, ({ projectId }) => {
        dispatch(updateProjectStatus(projectId, RESTARTING));
      });

      socket.on(ON_PROJECT_PAUSE, ({ projectId }) => {
        dispatch(updateProjectStatus(projectId, PAUSED));
      });

      socket.on(ON_PROJECT_DEACTIVATE, ({ projectId }) => {
        dispatch(updateProjectStatus(projectId, INACTIVE));
      });
    }
  }, [socket]);

  const logoutAsSwitchedUser = async (e) => {
    e.preventDefault();
    Cookies.remove(COOKIE_SWITCH_USER);
    router.push(ADMIN_DASHBOARD);
  };

  const links = [
    {
      url: PANEL_DASHBOARD,
      text: t('layouts.PanelLayout.index.dashboard'),
      icon: 'fa-chart-pie',
    },
    {
      url: PANEL_PROJECTS,
      text: t('layouts.PanelLayout.index.projects'),
      icon: 'fa-th-list',
    },
    {
      url: PANEL_BILLING,
      text: t('layouts.PanelLayout.index.billing'),
      icon: 'fa-coins',
    },
    {
      url: PANEL_HELP,
      text: t('layouts.PanelLayout.index.help'),
      icon: 'fa-info-circle',
    },
  ];

  return (
    <Wrapper fluid={true} className="py-0 px-0 px-md-5 align-content-between">
      <Container fluid={true} className="pt-3 pt-md-2 mb-3">
        <Header className="row align-content-center mb-2 my-lg-3">
          <Col xs={{ size: 6 }} xl={{ size: 2 }} className="order-1">
            <Link href={PANEL_DASHBOARD} passHref={true}>
              <a>
                <Image
                  layout="fixed"
                  height={38}
                  width={224}
                  src={logoSvg}
                  alt="trafficbooster.pro - Traffic for your website"
                />
              </a>
            </Link>
          </Col>

          <Col xs={{ size: 12 }} lg={{ size: 8 }} xl={{ size: 5, offset: 1 }} className="order-3 order-lg-2">
            <Navigation>
              {links.map(({ url, text, icon }) => (
                <NavItem key={text.toLowerCase()}>
                  <Link href={url} passHref={true} shallow={true}>
                    <HeaderLink className={url === router.route ? 'active' : ''}>
                      <i className={`fa ${icon}`} />
                      {text}
                    </HeaderLink>
                  </Link>
                </NavItem>
              ))}
            </Navigation>
          </Col>

          <Col xs={{ size: 12 }} md={{ size: 6 }} lg={{ size: 4 }} className="order-2 order-lg-3">
            <Row className="g-0">
              <Col className="align-self-center">
                <PackageName />
              </Col>
              <Col className="align-self-center">
                <AffiliateBalance />
              </Col>
              <Col className="align-self-center">
                <UncontrolledDropdown className="d-flex justify-content-end">
                  <ActiveUser />
                  <DropdownMenu>
                    <Link href={PANEL_PROFILE} shallow={true} passHref={true}>
                      <DropdownItem style={{ display: 'none' }}>
                        <i className="fa fa-user-alt" />
                        {t('layouts.PanelLayout.index.profile')}
                      </DropdownItem>
                    </Link>
                    <DropdownItem divider={true} style={{ display: 'none' }} />
                    {switchUser && (
                      <>
                        <a href="#" onClick={logoutAsSwitchedUser}>
                          <DropdownItem>
                            <i className="fa fa-sign-out-alt" />
                            Logout as {user?.username}
                          </DropdownItem>
                        </a>
                      </>
                    )}
                    {!switchUser && (
                      <>
                        <a href={LOGOUT}>
                          <DropdownItem>
                            <i className="fa fa-sign-out-alt" />
                            {t('layouts.PanelLayout.index.singOut')}
                          </DropdownItem>
                        </a>
                      </>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
          </Col>
        </Header>
        <Notifier token={user.token} />
      </Container>
      <Content fluid={true}>{children}</Content>
      <Container>
        <footer className="row justify-content-center align-content-center pb-5">
          <Copyright className="col-12">{t('layouts.PanelLayout.index.adPrideLLC')}</Copyright>
        </footer>
      </Container>
    </Wrapper>
  );
};

export const getLayout = (page) => <PanelLayout>{page}</PanelLayout>;

export default PanelLayout;
