import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { PANEL_BILLING } from 'src/const/routes';
import { durationToCountdownFormat } from 'src/utils/date';
import { trackClick } from 'src/api/metrics';
import { blackFridayPercents } from 'src/config.client';

const Container = styled(Row)`
  border-radius: 8px;
  background-image: linear-gradient(64.25deg, #7c154f 0%, #8766ec 60%, #fd851f 100%);
  cursor: pointer;
`;

const Icon = styled.span`
  display: inline-block;
  font-size: 42px;
  width: auto;
`;

const OfferTitle = styled.span`
  display: block;
  font-size: 22px;
  font-weight: 300;
  color: #ffffff;
`;

const OfferDetails = styled.span`
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
`;

const DiscountLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #35ea94;
  background-color: #16171b;
  border-radius: 2px;
  margin: 0 10px;
  padding: 2px 8px;
  font-style: italic;
`;

export const CtaButton = styled(Button)`
  font-size: 16px;
  padding: 4px 8px;
  border-radius: 4px;
  text-align: center;
  border: none;
  background-color: #16171b;
  text-transform: uppercase;
  color: #f9faff;
  font-weight: 400;
  margin-right: 10px;

  &:hover {
    background: linear-gradient(120deg, #5db8fb, #35ea94);
    color: #f9faff;
  }

  &:focus {
    box-shadow: none !important;
  }
`;

const Countdown = styled.span`
  font-size: 30px;
  font-weight: 300;
  color: #ffffff;

  > label {
    bottom: 0;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 300;
    color: #ffffff;
  }
`;

const BlackFridayNotifier = ({ duration, turnOff }) => {
  const { t } = useTranslation();
  const router = useRouter();

  const handleClick = () => {
    turnOff();
    trackClick('notifier_welcomeOffer');
    router.push(PANEL_BILLING).catch(console.error);
  };

  return (
    <Container
      className="justify-content-between align-content-center mx-0 mb-2 mb-lg-3 animate__animated"
      onClick={handleClick}
    >
      <Col className="p-4 px-lg-4 py-lg-2">
        <Row>
          <Col xs={2} md={1}>
            <Icon>🐵</Icon>
          </Col>
          <Col xs={10} md={11} lg={7}>
            <OfferTitle>{t('components.containers.notifier.blackFriday.title')}</OfferTitle>
            <OfferDetails>{t('components.containers.notifier.blackFriday.details')}</OfferDetails>
            <DiscountLabel>-{blackFridayPercents}%</DiscountLabel>
          </Col>
          <Col xs={12} lg={4} className="text-end mt-3">
            <CtaButton>{t('components.containers.notifier.blackFriday.cta')}</CtaButton>
            <Countdown>
              <label>{t('components.containers.notifier.blackFriday.demoEndsLabel')}</label>
              {durationToCountdownFormat(duration)}
            </Countdown>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default BlackFridayNotifier;
