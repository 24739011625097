import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import Loader from 'src/components/common/loader';
import { ACTIVE_PACKAGE, PANEL_BILLING } from 'src/const/routes';
import Link from 'next/dist/client/link';
import styled from 'styled-components';

export const PlanLabel = styled.span`
  font-size: 11px;
  color: #b9b9b9;
  font-weight: 300;
  margin-right: 10px;
`;

export const Plan = styled.span`
  font-size: 12px;
  color: #ffffff;
  border-radius: 8px;
  background: linear-gradient(30deg, #5db8fb, #f81ffd);
  font-weight: 700;
  padding: 4px 8px;

  > i {
    margin-right: 5px;
  }
`;

const PackageName = () => {
  const packageName = useSelector((state) => state.app.packageInfo.fullName);
  const { t } = useTranslation();

  return (
    <Link href={ACTIVE_PACKAGE} passHref={true} shallow={true}>
      <a className="d-flex justify-content-lg-end">
        <PlanLabel className="d-none d-xl-inline my-auto">
          {t('components.containers.packageName.planLabel')}:
        </PlanLabel>
        <Loader isLoading={!packageName}>
          <Plan>
            <i className="fa fa-rocket" />
            {packageName}
          </Plan>
        </Loader>
      </a>
    </Link>
  );
};

export default PackageName;
