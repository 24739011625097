import Cookies from 'js-cookie';

const baseUrl = process.env.NEXT_PUBLIC_TDS_API_BASE_URL;

const createConversion = async (dto) => {
  try {
    if (!baseUrl) {
      console.log('TDS API base URL is not set. Conversion will not be sent.');
      return;
    }

    const response = await fetch(`${baseUrl}/v1/tds/conversions`, {
      method: 'POST',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();

    if (!response.ok) {
      console.warn(`TDS API call failed: ${response.status} ${data.message}`);
    }
  } catch (error) {
    console.error('Error creating conversion', error);
  }
};

export const trackTdsLead = () => {
  const cpid = Cookies.get('cpid');

  if (cpid) {
    createConversion({
      cpid,
      type: 'lead',
    });
  }
};

export const trackTdsRegistration = () => {
  const cpid = Cookies.get('cpid');

  if (cpid) {
    createConversion({
      cpid,
      type: 'registration',
    });
  }
};

export const trackTdsPurchase = (value) => {
  const cpid = Cookies.get('cpid');

  if (cpid) {
    createConversion({
      cpid,
      type: 'purchase',
      value: value || 20.0,
    });
  }
};
