import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';

import { API_ME_FEATURE, BLOG_HOW_TO_IMPROVE_SEO_RANK } from 'src/const/routes';
import { useSelector } from 'react-redux';
import authRequest from 'lib/authRequest';
import { GA_CHECK_REQUIRED } from 'src/const/features';
import { trackClick } from 'src/api/metrics';

const Container = styled(Row)`
  border-radius: 8px;
  background-image: linear-gradient(to top, #48c6ef 0%, #6f86d6 100%);
`;

const Icon = styled.span`
  display: inline-block;
  font-size: 42px;
  width: auto;
`;

const OfferTitle = styled.span`
  display: block;
  font-size: 22px;
  font-weight: 300;
  color: #ffffff;
`;

const OfferDetails = styled.span`
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
`;

export const CtaButton = styled(Button)`
  font-size: 16px;
  padding: 4px 8px;
  border-radius: 4px;
  text-align: center;
  border: none;
  background-color: #16171b;
  text-transform: uppercase;
  color: #f9faff;
  font-weight: 400;
  margin-right: 10px;

  &:hover {
    background: linear-gradient(120deg, #5db8fb, #35ea94);
    color: #f9faff;
  }

  &:focus {
    box-shadow: none !important;
  }
`;

const Countdown = styled.span`
  font-size: 30px;
  font-weight: 300;
  color: #ffffff;

  > label {
    bottom: 0;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 300;
    color: #ffffff;
  }
`;

const STATUS_NEW = 'new';
const STATUS_SUCCESS = 'success';
const STATUS_FAIL = 'fail';

const GaCheckNotifier = ({ turnOff }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(STATUS_NEW);
  const token = useSelector((state) => state.user.token);

  const handleConfirmClick = () => {
    authRequest(API_ME_FEATURE.replace('{feature}', GA_CHECK_REQUIRED), token, {
      method: 'DELETE',
    }).catch(console.error);
    trackClick('notifier_gaCheck_confirm');
    setStatus(STATUS_SUCCESS);
  };

  const handleRejectClick = () => {
    trackClick('notifier_gaCheck_reject');
    setStatus(STATUS_FAIL);
  };

  const handleRecheckClick = () => {
    trackClick('notifier_gaCheck_checkAgain');
    setStatus(STATUS_NEW);
  };

  const handleMoreClick = () => {
    turnOff();
    trackClick('notifier_gaCheck_learnMore');
    window.open(BLOG_HOW_TO_IMPROVE_SEO_RANK, '_blank');
  };

  return (
    <Container className="justify-content-between align-content-center mx-0 mb-2 mb-lg-3 animate__animated">
      <Col className="p-4 px-lg-4 py-lg-2">
        {status === STATUS_NEW && (
          <Row className="justify-content-between">
            <Col xs={2} md={1}>
              <Icon>🕵️‍♂️</Icon>
            </Col>
            <Col xs={10} md={11} lg={7}>
              <OfferTitle>{t('components.containers.notifier.gaCheck.new.title')}</OfferTitle>
              <OfferDetails>{t('components.containers.notifier.gaCheck.new.details')}</OfferDetails>
            </Col>
            <Col xs={12} lg={4} className="text-center text-lg-end">
              <Countdown className="text-end d-block ms-auto mt-3">
                <CtaButton onClick={handleConfirmClick}>
                  {t('components.containers.notifier.gaCheck.new.confirm')}
                </CtaButton>
                <CtaButton onClick={handleRejectClick}>
                  {t('components.containers.notifier.gaCheck.new.reject')}
                </CtaButton>
              </Countdown>
            </Col>
          </Row>
        )}
        {status === STATUS_SUCCESS && (
          <Row className="justify-content-between">
            <Icon>🥳</Icon>
            <Col className="align-content-center d-inline-block" sm={8}>
              <OfferTitle>{t('components.containers.notifier.gaCheck.success.title')}</OfferTitle>
              <OfferDetails>{t('components.containers.notifier.gaCheck.success.details')}</OfferDetails>
            </Col>
            <Col className="d-inline-block position-relative" sm={3}>
              <Countdown className="text-end d-block ms-auto mt-3">
                <CtaButton onClick={handleMoreClick}>
                  {t('components.containers.notifier.gaCheck.success.cta')}
                </CtaButton>
              </Countdown>
            </Col>
          </Row>
        )}
        {status === STATUS_FAIL && (
          <Row className="justify-content-between">
            <Icon>😔</Icon>
            <Col className="align-content-center d-inline-block" sm={8}>
              <OfferTitle>{t('components.containers.notifier.gaCheck.fail.title')}</OfferTitle>
              <OfferDetails>{t('components.containers.notifier.gaCheck.fail.details')}</OfferDetails>
            </Col>
            <Col className="d-inline-block position-relative" sm={3}>
              <Countdown className="text-end d-block ms-auto mt-3">
                <CtaButton onClick={handleRecheckClick}>
                  {t('components.containers.notifier.gaCheck.fail.cta')}
                </CtaButton>
              </Countdown>
            </Col>
          </Row>
        )}
      </Col>
    </Container>
  );
};

export default GaCheckNotifier;
