import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { PANEL_AFFILIATE } from 'src/const/routes';
import Link from 'next/dist/client/link';
import styled from 'styled-components';

export const Label = styled.span`
  font-size: 12px;
  color: #ffffff;
  border-radius: 8px;
  background: linear-gradient(30deg, #5d61fb, #fc8673, #f81ffd);
  font-weight: 700;
  padding: 4px 8px;

  > i {
    margin-right: 5px;
  }
`;

const AffiliateBalance = () => {
  const balances = useSelector((state) => state.app.affiliateBalances);
  const { t } = useTranslation();

  let balance = balances.filter((x) => x.currency === 'USD').shift();
  if (!balance) {
    balance = balances.filter((x) => x.amount > 0).shift();
  }

  return (
    <Link href={PANEL_AFFILIATE} passHref={true} shallow={true}>
      <a className="d-flex justify-content-lg-end">
        {!balance && (
          <Label className="my-auto">
            <i className="fa fa-hand-holding-usd" />
            {t('components.containers.affiliateBalance.start')}
          </Label>
        )}
        {!!balance && (
          <Label className="my-auto">
            <i className="fa fa-hand-holding-usd" />
            {balance.currency} {balance.amount.toFixed(2)}
          </Label>
        )}
      </a>
    </Link>
  );
};

export default AffiliateBalance;
