import React, { useEffect, useState } from 'react';

const Animation = ({ children, show = true, inAnimation = 'fadeIn', outAnimation = 'fadeOut' }) => {
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) {
      setRender(true);
    }
  }, [show]);

  return (
    render && (
      <div
        className={`animate__animated animate__${show ? inAnimation : outAnimation}`}
        onAnimationEnd={() => !show && setRender(false)}
      >
        {children}
      </div>
    )
  );
};

export default Animation;
