import axios from 'axios';
import { API_METRICS_CLICK } from 'src/const/routes';
import ReactGA4 from 'react-ga4';
import TagManager from 'react-gtm-module';
import FacebookPixel from '../utils/facebookPixel';
import { trackTdsLead, trackTdsPurchase, trackTdsRegistration } from 'lib/tdsPixel';

export const trackClick = (trackId) => {
  // TODO add JWT token
  axios.post(API_METRICS_CLICK.replace('{element}', trackId)).catch(console.error);
};

export const trackLead = () => {
  trackTdsLead();
};

export const trackLogin = () => {
  ReactGA4.event('login');
};

export const trackRegistration = () => {
  FacebookPixel.track(FacebookPixel.COMPLETE_REGISTRATION);
  ReactGA4.event('sign_up');
  trackTdsRegistration();
};

export const trackAddToCard = ({ currency, amount, packageName, packageCurrency, packageAmount, packageFeature }) => {
  ReactGA4.event('add_to_cart', {
    currency: currency,
    value: amount.toFixed(2),
    items: [
      {
        item_name: packageName,
        price: packageAmount,
        currency: packageCurrency,
        promotion_name: packageFeature ?? '',
      },
    ],
  });
};

export const trackPurchase = ({
  id,
  currency,
  amount,
  packageName,
  packageCurrency,
  packageAmount,
  packageFeature,
}) => {
  FacebookPixel.track(FacebookPixel.SUBSCRIBE, { currency, value: amount.toFixed(2) });

  trackTdsPurchase(amount);

  TagManager.dataLayer({
    dataLayer: { event: 'purchase', purchaseAmount: amount.toFixed(2), purchaseCurrency: currency },
  });

  ReactGA4.event('purchase', {
    currency: currency,
    value: amount.toFixed(2),
    transaction_id: id,
    items: [
      {
        item_name: packageName,
        price: packageAmount,
        currency: packageCurrency,
        promotion_name: packageFeature ?? '',
      },
    ],
  });
};
